<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="music-player">
                <ngx-audio-player autoPlay="true" muted="muted"    
                    [playlist]="mssapPlaylist"
                    [disablePositionSlider]="msaapDisablePositionSlider"
                    [displayRepeatControls]="msaapDisplayRepeatControls"
                    [displayVolumeControls]="msaapDisplayVolumeControls"
                    [displayVolumeSlider]="msaapDisplayVolumeSlider"
                    
                    [displayTitle]="msaapDisplayTitle"
                
                    [displayPlaylist]="msaapDisplayPlayList"
                    [pageSizeOptions]="msaapPageSizeOptions"
                
                    [tableHeader]="msaapTableHeader"
                    [titleHeader]="msaapTitleHeader"
                    [artistHeader]="msaapArtistHeader"
                    [durationHeader]="msaapDurationHeader"
                
                    [displayArtist]="msaapDisplayArtist"
                    [displayDuration]="msaapDisplayDuration"
                    [expanded]="true"
                
                    (trackPlaying)="onTrackPlaying($event)"
                    (trackPaused)="onTrackPaused($event)"
                    (trackEnded)="onEnded($event)"
                    (nextTrackRequested)="onNextTrackRequested($event)"
                    (previousTrackRequested)="onPreviousTrackRequested($event)"
                    (trackSelected)="onTrackSelected($event)">
                </ngx-audio-player> 
            </div>

            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <!-- <h1>{{"Banner.Pretitle" | translate}}</h1> -->
                </div>
                <div class='banner-title'>
                    <h2>{{ "Banner.Name" | translate }}</h2>
                    <h3 style="color:#9a9a9a">{{ "Banner.Poems" | translate }}</h3 >
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <!-- <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")' href="mailto:andresjosehr@gmail.com" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div> -->
        </div>
        <div>
        </div>
    </div>
</section>
