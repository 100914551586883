import { Component, OnInit, AfterViewInit } from '@angular/core';

import {trigger, state, style, animate, transition, stagger, query } from "@angular/animations"
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Track } from 'ngx-audio-player'; 
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('bannerTrigger', [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class BannerComponent implements OnInit {

  // Main Player Controls
  msaapDisplayPlayList = false;
  msaapDisablePositionSlider = true;
  msaapDisplayRepeatControls = false;
  msaapDisplayVolumeControls = true;
  msaapDisplayVolumeSlider = false;

  // Title Marquee
  msaapDisplayTitle = true;

  // Playlist Controls
  msaapPageSizeOptions = [2,4,6];
  msaapDisplayArtist = true;
  msaapDisplayDuration = true;

  // For Localisation
  msaapTableHeader = 'Pesme';
  msaapTitleHeader = 'My Title';
  msaapArtistHeader = 'My Artist';
  msaapDurationHeader = 'My Duration';


  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void { 
  }
  

  // Material Style Simple Audio Player
  mssapPlaylist: Track[] = [
    {
      title: 'ЦУНЕР НЕБОМ СРЦЕ ШАРА',
      link: 'assets/audio/16-2-cuner_nebom_srce_sara.mp3',
      artist: 'XVI Фестивал "ЗЛАТНА ТАМБУРИЦА" - Нова војвођанска песма',
      duration: 211
    },
    {
      title: 'У БЕЋАРА СНОВИ ПУСТИ',
      link: 'assets/audio/19-19-u_becara_snovi_pusti.mp3',
      artist: 'XVIII Фестивал "ЗЛАТНА ТАМБУРИЦА" - Избор најлепше војвођанске песме',
      duration: 226
    },
    {
      title: 'ЈУЛИШКА',
      link: 'assets/audio/juliska.mp3',
      artist: '[ADD_TEXT]',
      duration: 186
    },
    {
      title: 'ЧАРДАК МАЛИ А ЉУБАВ ВЕЛИКА',
      link: 'assets/audio/17-x-cardak_mali_a_ljubav_velika.mp3',
      artist: 'XVII Фестивал "ЗЛАТНА ТАМБУРИЦА" - Избор најлепше војвођанске песме',
      duration: 211
    }
  ];

  onTrackPlaying(event) {
    console.log(event);
    // your logic which needs to
    // be triggered once the
    // track ends goes here.
  }


  onTrackPaused(event) {
      console.log(event);
      // your logic which needs to
      // be triggered once the
      // track ends goes here.
  }

  onEnded(event) {
      console.log(event);
      // your logic which needs to
      // be triggered once the
      // track ends goes here.
  }

  onNextTrackRequested(event) {
      console.log(event);
      // your logic which needs to
      // be triggered once the
      // track ends goes here.
  }


  onPreviousTrackRequested(event) {
      console.log(event);
      // your logic which needs to
      // be triggered once the
      // track ends goes here.
  }

  onTrackSelected(event) {
      console.log(event);
      // your logic which needs to
      // be triggered once the
      // track ends goes here.
  }  

}
